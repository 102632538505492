
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/anz/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  text-align: center;

  .images {
    margin: 25px auto 0;

    img {
      margin: auto;
      display: block;
      max-height: 345px;
    }
  }
}

.content .tipsHeader .title {
  margin-top: 25px;
  width: 100%;
}
