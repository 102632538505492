
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/anz/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  .title {
    color: var(--loading-text-color);
    margin-top: -33px;
    margin-bottom: 7px;
    font-size: 19px;
  }

  .subtitle {
    font-size: 14px;
    color: var(--loading-progress-bar);
    margin-bottom: 25px;
  }

  .spinner {
    img {
      animation: rotate 1s linear infinite;
      width: 70px;
    }
    svg {
      animation: rotate 1s linear infinite;
      width: 70px;
    }
    .svg {
      fill: var(--loading-progress-bar);
    }
  }

  &.dark {
    background: black;
  }

  .svgDot {
    path {
      fill: var(--loading-dot);
    }
  }
  .top {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
  }

  .bottom {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
  }
}
