
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/anz/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-bottom: 1.5rem; //24
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 1.1;
    letter-spacing: -0.03125rem; //-0.5px
  }

  .description {
    text-align: left;
  }

  .animation-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: var(--page-background-color);
  }
}
