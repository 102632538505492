
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/anz/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  .content {
    height: calc(100% - 108px);
    overflow-y: auto;
    margin-bottom: 120px;
  }
  h3 {
    font-weight: 600;
  }
  .subheading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    font-weight: 400;
    font-size: 1.5rem !important; //24px
    line-height: 1.1;
    letter-spacing: -0.03125rem; //-0.5px
  }
  /*p,
    ul li {
        font-weight: 300;
    }*/

  ul {
    margin-bottom: 25px;
    margin-top: 20px !important;
  }
}
