$c-primary: #004165;
$c-secandary: #0072ac;
$dots: false;
$version: 3.1;

$loading-bg-overrided: white;
$progress-bar-overided: $c-secandary;
$progress-bar-incomplete-overided: $c-primary;
$body-color-overrided: #494949;
$heading-color-overrided: $c-primary;
$flow-v2-bg-header-overrided: linear-gradient(89.7deg, #004165 37.36%, #036da0 97.11%);
$heading-font-size-overrided: 2rem; //32
$flow-v2-header-size-overrided: 3.75rem; //60

@font-face {
  font-family: 'MyriadPro';
  src: url('#{$project-font-path}MyriadPro-SemiBold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadPro';
  src: url('#{$project-font-path}MyriadPro-SemiBoldItalic.otf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'MyriadPro';
  src: url('#{$project-font-path}MyriadPro-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadPro';
  src: url('#{$project-font-path}MyriadPro-Light.otf');
  font-weight: 300;
  font-style: normal;
}

$base-font-family: 'MyriadPro', sans-serif !important;
$font-family-heading: 'MyriadPro', sans-serif !important;

.b-icon-warning {
  visibility: hidden;
}
