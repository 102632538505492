
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/anz/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  background: rgba(0, 0, 0, 0.7);
  height: 0;
  overflow: hidden;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  &.open {
    height: auto;
    opacity: 1;
  }

  .modalContent {
    outline: none;
    opacity: 0;
    width: 90%;
    max-width: 520px;
    -webkit-transform: translateY(3%);
    transform: translateY(3%);
    -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
    transition: opacity 0.2s, -webkit-transform 0.2s;
    transition: opacity 0.2s, transform 0.2s;
    transition: opacity 0.2s, transform 0.2s, -webkit-transform 0.2s;
    max-height: calc(90vh - 1.5rem);

    &:after {
      content: '';
      display: block;
      height: 10vh;
    }

    &.open {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    .dialog {
      min-width: 280px;
      padding: 20px 20px 0;
      border-radius: 8px;
      background: var(--page-background-color);
      box-shadow: none;
      font-family: var(--base-font-family) !important;
      line-height: 24px;
      .heading {
        font-family: var(--heading-font-family) !important;
        margin: 15px 0;
        text-align: left;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: var(--primary);

        div {
          margin-bottom: 25px;
          width: 44px;
          height: 44px;
        }

        span {
          font-family: var(--base-font-family) !important;
          display: block;
        }
      }
    }

    &.small {
      .dialog {
        max-width: 310px;
        min-width: inherit;
        margin: auto;
      }
    }
  }
}

@media (max-width: 760px) {
  .wrapper {
    .dialog {
      min-width: 100%;
    }
  }
}
