
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/anz/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  //padding-top: 84px;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background: var(--page-background-color);

  .body {
    flex: 1;
    padding: 0 20px;
    margin: auto;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 88px;
    left: 0;
    right: 0;
    overflow: auto;
    word-wrap: break-word;
  }

  .footer {
    padding: 0 20px;
    width: 100%;
    background: var(--page-background-color);
    position: absolute;
    bottom: 0;
    height: 88px;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 2;
  }

  .footerShadow {
    background: var(--page-background-color);
    box-shadow: 0px -21px 30px rgba(0, 0, 0, 0.1);
  }

  .privacyFooter {
    position: fixed;
    bottom: 0;
    background-color: $c-grey-light;
  }

  .isDark {
    background: #000 !important;
  }
}

.isDark {
  background: #000 !important;
}
