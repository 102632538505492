
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/anz/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$green: #07bc0c;
$warning: #ffc107;

.wrapper {
  //background: var(--page-background-color);
  z-index: 100;
  text-align: center;

  .content_center_vertical {
    margin: auto !important;
  }

  .content {
    max-width: 21.875rem; //350
    text-align: left;

    & > div {
      text-align: left;
      font-size: 1rem;
      margin-bottom: 1.875rem; //10
    }
    .issue {
      text-align: center;
      z-index: 350;
    }

    .heading {
      font-family: var(--heading-font-family) !important;
      color: var(--heading-text-color) !important;
      font-size: var(--heading-font-size) !important;
      margin-bottom: 1.875rem; //30
      line-height: 1.2;
      font-weight: 600;
      letter-spacing: -0.03125rem; //-0.5px
      text-align: left;
    }

    .heading_text_align_center {
      text-align: center !important;
    }

    .title {
      margin-bottom: 1.25rem; //20
      font-size: 1rem;
    }

    .description {
      text-align: left;
    }

    .description_text_align_center {
      text-align: center !important;
    }

    .img-icon {
      width: auto;
      height: 4rem; //64
    }

    .dark,
    .landscape {
      background: #000;
      color: #fff;
    }

    .landscape {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;

      .content {
        max-width: 15.625rem; //250
        border: 1px solid;
        border-radius: 0.3125rem; //5
        margin: auto;
        padding: 1.875rem; //30
        text-align: center;

        img {
          display: block;
          width: 3.75rem; //60
          margin: 1.5rem auto 0;
        }
      }
    }

    ul li {
      font-weight: 300;
    }

    ul {
      margin-bottom: 25px;
      margin-top: 20px !important;
    }

    ol {
      margin: 0;
      padding: 0;
      text-align: left;
      list-style: none;
      counter-reset: my-awesome-counter;

      li {
        margin-bottom: 1rem;
        padding-left: 1.875rem; //30
        counter-increment: my-awesome-counter;
        position: relative;
        font-size: 1rem;
        &:before {
          content: counter(my-awesome-counter);
          position: absolute;
          left: 0.5625rem; //9
          top: 0.125rem; //2
          font-size: 0.75rem; //12
        }
      }
    }
  }
}
